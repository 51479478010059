import React, { useState, useEffect } from 'react';
import Navbar from './components/Navbar';
import Graphs from './components/Graphs';
import Header from './components/Header';
import Docs from './components/Docs';
import Checklist from './components/Checklist';
import Form from './components/Form';
import Todo from './components/Todo';
import ProjectGraph from './components/ProjectGraph';
import Footer from './components/Footer';
import InvestmentStrat from './components/InvestmentStrat';
import Login from './components/Login';
import ToDoLists from './components/ToDoLists';
import GoogleCalendar from './components/GoogleCalendar';
import Priorities from './components/Priorities';
import { auth } from "./firebase";
import './App.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setIsAuthenticated(true);
      } else {
        setIsAuthenticated(false);
      }
    });

    return unsubscribe;
  }, []);

  const handleLogout = async () => {
    await auth.signOut();
    setIsAuthenticated(false);
  };

  if (!isAuthenticated) {
    return (
      <div className='l33'>
        <h1>Lab3 Dashboard</h1>
        <Login />
      </div>
    );
  }

  return (
    <div className="app">
      <Header />
      <Navbar />
      <Graphs />
      <GoogleCalendar />
      <Todo />
      <ToDoLists/>
      <Priorities />
      <InvestmentStrat/>
      <ProjectGraph />
      <Form />
      <Checklist />
      <Docs />
      <Footer />
    </div>
  );
}

export default App;