import React from 'react';

function Todo() {
  return (
    <div className="todo-container">
      <h1>To Do</h1>
      <div className="todo-row">
        <div className="todo-card">
          <h2>Daily</h2>
          <ul>
            <li><a href="#">Daily Predictions Sheet</a></li>
            <li><a href="https://docs.google.com/spreadsheets/d/1NkLrUL72KEfH70neYltxG64UPShM24eO8j6xBFIqQ-M/edit#gid=1262839641">P/L R/S/L</a></li>
            <li><a href="https://www.accointing.com/app/portfolio/overview">P/L Tokens</a></li>
            <li><a href="https://docs.google.com/spreadsheets/d/1ZccHQQfW_wKnJIxko9GFsM7ruwNPqjm3VXFLD7C9baY/edit#gid=1589314090">KuCoin Bot Tracker</a></li>
            <li>
              <a href="https://docs.google.com/spreadsheets/d/1hFR_vTLZj77bsT-Z18r_uartuMbLq1XEODEoCGnKAcc/edit#gid=87761426">Project Expense</a>
              <ul>
                <li><a href="https://drive.google.com/drive/folders/1uCGF6OKXc9CkKhX4EfYgThsoLqF5d0rv?usp=share_link">BLXN</a></li>
                <li><a href="https://drive.google.com/drive/folders/1uCGF6OKXc9CkKhX4EfYgThsoLqF5d0rv?usp=share_link">WM</a></li>
              </ul>
            </li>

            <li><a href="#">General Expense</a></li>
            <li><a href="#">Marketing Maintanance</a></li>
          </ul>
        </div>
        <div className="todo-card">
          <h2>Weekly</h2>
          <ul>
            <li><a href="https://drive.google.com/drive/folders/13KHDIoMmgz5oKZWCelyPYtVfmMjNodI5">Meeting Notes</a></li>
            <li><a href="https://docs.google.com/spreadsheets/d/1hFR_vTLZj77bsT-Z18r_uartuMbLq1XEODEoCGnKAcc/edit#gid=87761426">Project Records</a></li>
            <li><a href="https://docs.google.com/spreadsheets/d/1NkLrUL72KEfH70neYltxG64UPShM24eO8j6xBFIqQ-M/edit#gid=1539093411">Salary Payments</a></li>
            <li><a href="#">Weekly Blog Posts</a></li>
            <li><a href="https://next.waveapps.com/4b57ec82-e1c9-465c-b39e-28b2958e0dde/dashboard/">External Bookkeeping</a></li>
            <li><a href="#">Weekly Reports</a></li>
          </ul>
        </div>
        <div className="todo-card">
          <h2>Quarterly</h2>
          <ul>
            <li><a href="#">Quarterly Reports</a></li>
            <li><a href="https://docs.google.com/spreadsheets/d/1NkLrUL72KEfH70neYltxG64UPShM24eO8j6xBFIqQ-M/edit#gid=1214077230">Registrations & Fees</a></li>
            <li><a href="#">Tax Documents</a></li>
          </ul>
        </div>
        <div className="todo-card">
          <h2>Annually</h2>
          <ul>
            <li><a href="#">Annual Reports</a></li>
            <li><a href="#">Tax Filings</a></li>
            <li><a href="https://docs.google.com/spreadsheets/d/1NkLrUL72KEfH70neYltxG64UPShM24eO8j6xBFIqQ-M/edit#gid=1214077230">Registration & Fees</a></li>
          </ul>
        </div>
      </div>
      <div className="header-text">
        <h1>Current Internal Structure</h1>
      </div>
      <div className="image-container">
        <img src="https://i.ibb.co/P6WX5h3/Screenshot-2023-03-03-at-7-59-23-AM.png" alt="TM AppDefi logo" />
      </div>
      <div className="todo-row">
        <div className="todo-card">
          <h2>Nahid</h2>
          <ul>
            <li><a href="#">Frontend Manager</a></li>
            <li><a href="#">Frontend Developer</a></li>
            <li><a href="#">Designer</a></li>
            <li><a href="#">Finance Manager</a></li>
            <li><a href="#">Social Media</a></li>
            <li><a href="#">BizDev Manager</a></li>
            <li><a href="#">Wallet Ops</a></li>
            <li><a href="#">Investor</a></li>
          </ul>
        </div>
        <div className="todo-card">
          <h2>Ish</h2>
          <ul>
            <li><a href="#">Finance Manager</a></li>
            <li><a href="#">External Bookkeeper</a></li>
            <li><a href="#">Internal Bookkeeper</a></li>
            <li><a href="#">Investor</a></li>
          </ul>
        </div>
        <div className="todo-card">
          <h2>Raff</h2>
          <ul>
            <li><a href="#">Marketing Manager</a></li>
            <li><a href="#">Designer</a></li>
            <li><a href="#">Social Media</a></li>
            <li><a href="#">Finance Manager</a></li>
            <li><a href="#">Investor</a></li>
          </ul>
        </div>
        <div className="todo-card">
          <h2>EP</h2>
          <ul>
            <li><a href="#">Backend Manager</a></li>
            <li><a href="#">Backend Developer</a></li>
            <li><a href="#">Finance Manager</a></li>
            <li><a href="#">BizDev Manager</a></li>
            <li><a href="#">External Bookkeeper</a></li>
            <li><a href="#">Internal Bookkeeper</a></li>
            <li><a href="#">Investor</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Todo;
