import React, { useState, useEffect } from "react";
import { collection, onSnapshot, addDoc, deleteDocs, writeBatch, getDocs } from "firebase/firestore";
import { db } from "../firebase";

function ToDoLists() {
const [nahidList, setNahidList] = useState([]);
const [epList, setEpList] = useState([]);
const [newNahidTask, setNewNahidTask] = useState("");
const [newEpTask, setNewEpTask] = useState("");
const [raffList, setRaffList] = useState([]);
const [newRaffTask, setNewRaffTask] = useState("");
const [ishList, setIshList] = useState([]);
const [newIshTask, setNewIshTask] = useState("");


  useEffect(() => {
    const unsubscribeNahid = onSnapshot(collection(db, "lists"), (snapshot) => {
      const nahidData = snapshot.docs
        .filter((doc) => doc.data().owner === "nahid")
        .map((doc) => doc.data().task);
      setNahidList(nahidData);
    });
    const unsubscribeEp = onSnapshot(collection(db, "lists"), (snapshot) => {
      const epData = snapshot.docs
        .filter((doc) => doc.data().owner === "ep")
        .map((doc) => doc.data().task);
      setEpList(epData);
    });
    const unsubscribeRaff = onSnapshot(collection(db, "lists"), (snapshot) => {
      const raffData = snapshot.docs
        .filter((doc) => doc.data().owner === "raff")
        .map((doc) => doc.data().task);
      setRaffList(raffData);
    });
    const unsubscribeIsh = onSnapshot(collection(db, "lists"), (snapshot) => {
      const ishData = snapshot.docs
        .filter((doc) => doc.data().owner === "ish")
        .map((doc) => doc.data().task);
      setIshList(ishData);
    });
    return () => {
      unsubscribeNahid();
      unsubscribeEp();
      unsubscribeRaff();
      unsubscribeIsh();
    };
  }, []);

  const handleAddNahidItem = async () => {
    if (newNahidTask.trim() !== "") {
      const newTask = newNahidTask.trim();
      await addDoc(collection(db, "lists"), { owner: "nahid", task: newTask });
      setNewNahidTask("");
    }
  };

  const handleAddEpItem = async () => {
    if (newEpTask.trim() !== "") {
      const newTask = newEpTask.trim();
      await addDoc(collection(db, "lists"), { owner: "ep", task: newTask });
      setNewEpTask("");
    }
  };

  const handleAddRaffItem = async () => {
    if (newRaffTask.trim() !== "") {
      const newTask = newRaffTask.trim();
      await addDoc(collection(db, "lists"), { owner: "raff", task: newTask });
      setNewRaffTask("");
    }
  };

  const handleAddIshItem = async () => {
    if (newIshTask.trim() !== "") {
      const newTask = newIshTask.trim();
      await addDoc(collection(db, "lists"), { owner: "ish", task: newTask });
      setNewIshTask("");
    }
  };

  const deleteList = async (owner) => {
    const listRef = collection(db, "lists");
    const querySnapshot = await getDocs(listRef);
    const batch = writeBatch(db);
  
    querySnapshot.forEach((doc) => {
      if (doc.data().owner === owner) {
        batch.delete(doc.ref);
      }
    });
  
    await batch.commit();
  };
  
  
  const handleClearNahidList = () => {
    deleteList("nahid");
  };
  
  const handleClearEpList = () => {
    deleteList("ep");
  };

  const handleClearRaffList = () => {
    deleteList("raff");
  };

  const handleClearIshList = () => {
    deleteList("ish");
  };
  
  

  return (
    <div className="to-do-lists-container">
      
      
      <div className="to-do-list">
        <h2 className="to-do-list-title">Nahid's To-Do List</h2>
        <ul className="to-do-list-items">
          {nahidList.map((item, index) => (
            <li key={index} className="to-do-list-item">
              {item}
            </li>
          ))}
        </ul>
        <div className="to-do-list-input">
          <input
            type="text"
            placeholder="New task"
            value={newNahidTask}
            onChange={(e) => setNewNahidTask(e.target.value)}
          />
          <button className="to-do-list-button" onClick={handleAddNahidItem}>
            Add Task
          </button>
          <button className="to-do-list-button" onClick={handleClearNahidList}>
            Clear List
          </button>
        </div>
      </div>

      <div className="to-do-list">
        <h2 className="to-do-list-title">EP's To-Do List</h2>
            <ul className="to-do-list-items">
            {epList.map((item, index) => (
            <li key={index} className="to-do-list-item">
            {item}
            </li>
            ))}
            </ul>
            <div className="to-do-list-input">
            <input
            type="text"
            placeholder="New task"
            value={newEpTask}
            onChange={(e) => setNewEpTask(e.target.value)}
            />
            <button className="to-do-list-button" onClick={handleAddEpItem}>
            Add Task
            </button>
            <button className="to-do-list-button" onClick={handleClearEpList}>
            Clear List
            </button>
            </div>
            </div>

            <div className="to-do-list">
        <h2 className="to-do-list-title">Raff's To-Do List</h2>
        <ul className="to-do-list-items">
          {raffList.map((item, index) => (
            <li key={index} className="to-do-list-item">
              {item}
            </li>
          ))}
        </ul>
        <div className="to-do-list-input">
          <input
            type="text"
            placeholder="New task"
            value={newRaffTask}
            onChange={(e) => setNewRaffTask(e.target.value)}
          />
          <button className="to-do-list-button" onClick={handleAddRaffItem}>
            Add Task
          </button>
          <button className="to-do-list-button" onClick={handleClearRaffList}>
            Clear List
          </button>
        </div>
      </div>

      <div className="to-do-list">
        <h2 className="to-do-list-title">Ish's To-Do List</h2>
        <ul className="to-do-list-items">
          {ishList.map((item, index) => (
            <li key={index} className="to-do-list-item">
              {item}
            </li>
          ))}
        </ul>
        <div className="to-do-list-input">
          <input
            type="text"
            placeholder="New task"
            value={newIshTask}
            onChange={(e) => setNewIshTask(e.target.value)}
          />
          <button className="to-do-list-button" onClick={handleAddIshItem}>
            Add Task
          </button>
          <button className="to-do-list-button" onClick={handleClearIshList}>
            Clear List
          </button>
        </div>
      </div>

            </div>
            );
            }

export default ToDoLists;



