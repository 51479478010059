import React, { useState } from 'react';

function Form() {
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <div className="form-button-container">
        <button className="form-button" onClick={handleClick}>Submit Meeting Topic</button>
      </div>
      {isOpen && (
        <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSchRXsPNkz_gfUf9_kjSt2KT7vVEFouWwJsqC_yaysU80zH0Q/viewform?embedded=true" width="640" height="900" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
      )}
    </div>
  );
}

export default Form;