import React, { useState } from 'react';

function Checklist() {
  const [goals, setGoals] = useState([
    {
      quarter: 'Q1',
      items: [
      { text: 'Release WM v2 Liquid and Split Staking Pools', completed: false },
      { text: 'Create WM community engagement plan', completed: false },
      { text: 'WM v2 March Distribution', completed: false },
      { text: 'Register off-shore and on-shore businesses', completed: true },
      { text: 'Agree on a decision to move forward with by Saturday, Feb 11', completed: false },
      { text: 'Investments', completed: false },
      { text: 'Wallet restructuring – DUE BY SAT MARCH 11', completed: false },
      { text: 'Token Hodling + Stables Defi (lending+staking+IDO+launchpad)', completed: false },
      { text: 'ROI dApps (risk)', completed: false },
      { text: 'Trading Manual + Bots Funds + Perps', completed: false },
      { text: 'Update our active positions Nahid Kamal', completed: false },
      { text: 'MEV', completed: false },
      { text: 'Root investigation', completed: false },
      { text: 'Ethernaut Completion – DUE MARCH 31', completed: false },
      { text: 'Revisit Trickle progress + have discussions about what the full functionality should be + build', completed: false },
      { text: 'Focus on marketing', completed: false },
      { text: 'Settle on DeFi mixing strategy utilizing services & CEXs – document the process step-by-step – DUE BY SAT MARCH 11', completed: false },
      { text: 'Bring in someone to regularly keep records of our investments', completed: false },
      { text: 'Determine who will be in charge of LLC / Structured business record-keeping requirements', completed: false },
      { text: 'Think about another BLXN dApp idea that can be running in loops along-side BLXN Limitless', completed: false },
      { text: 'Find one additional income stream with a 10-15K budget (external)', completed: false }
      ],
      },
      {
      quarter: 'Q2',
      items: [
      { text: 'Create WM community engagement plan (ie. hosting AMAs, community discussions, investment collaboration)', completed: false },
      { text: 'WM v2 May Distribution', completed: false },
      { text: 'WM v2 Voting Release', completed: false },
      { text: 'Investments', completed: false },
      { text: 'Maintain our presence as Team BLXN in the DeFi space', completed: false },
      { text: 'Hire a business development role in Trickle Pay for outreach and acquisition of private clients that want custom payroll/payment solutions', completed: false },
      { text: 'Think about IPFS usage along-side agreement drafting and wallet signature agreements', completed: false },
      { text: 'Have pieces of content published every single day as a reinforcement for the reputability of Trickle Pay', completed: false },
      { text: 'Develop a full-time marketing strategy to grow adoption', completed: false },
      { text: 'Business development / custom solutions for companies', completed: false },
      { text: 'File our first quarterly tax filing as an organization (if no cash outs, report $0)', completed: false }
      ],
      },
      {
      quarter: 'Q3',
      items: [
      { text: 'File our first quarterly tax filing as an organization (if no cash outs, report $0)', completed: false }
      ],
      },
    {
      quarter: 'Q4',
      items: [],
    },
  ]);

  const handleToggle = (quarterIndex, itemIndex) => {
    const updatedGoals = [...goals];
    const updatedItem = { ...updatedGoals[quarterIndex].items[itemIndex] };
    updatedItem.completed = !updatedItem.completed;
    updatedGoals[quarterIndex].items[itemIndex] = updatedItem;
    setGoals(updatedGoals);
  };

  return (
    <div className="checklist-container">
      <h1 style={{ color: 'orange', textAlign: 'center' }}>Quarterly Goals</h1>
      {goals.map((quarter, quarterIndex) => (
        <div key={quarter.quarter}>
          <h2 style={{ color: 'orange' }}>{quarter.quarter}</h2>
          <ul>
            {quarter.items.map((item, itemIndex) => (
              <li key={itemIndex}>
                <label>
                  <input
                    type="checkbox"
                    checked={item.completed}
                    onChange={() => handleToggle(quarterIndex, itemIndex)}
                  />
                  <span>{item.text}</span>
                </label>
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
}

export default Checklist;
