import React from 'react';

function Header() {
  return (
    <div className="navbar-header">
      <h1>Internal Dashboard</h1>
    </div>
  );
}

export default Header;