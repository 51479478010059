import React from 'react';

// function Priorities() {
//   const categories = [
//     {
//       title: 'WM',
//       priorities: [
//         'Set All Timeline Stuff',
//         'Split Last Cleanup',
//         'Market for Split',
//         'Launch Split',
//         'Wallet Restructure',
//         'Governance Release Date',
//         'Governance Frontend',
//         'Wealth New Investments',
//         'Maintain overall marketing for both platforms',
//       ],
//     },
//     {
//       title: 'COMPANY',
//       priorities: [
//         'Slide Documents',
//         'Respond to lawyer documents',
//         'Finish Project Recording Sheet',
//         'Finish all P/L Sheets',
//         'Have investment strategy meeting this week',
//         'Finish all finance role requirements',
//         'Discuss new hire onboarding- 75kBDT/mo',
//         'Have WaveApps all setup, and invoice ready',
//       ],
//     },
//     {
//       title: 'BLXN+TRICKLE',
//       priorities: [
//         'Think of final details for BLXN new idea',
//         'New BLXN frontend',
//         'New BLXN budget',
//         'New BLXN marketing strategy',
//         'Discuss Trickle Architecture',
//         'Set Trickle timeline',
//       ],
//     },
//     {
//       title: 'MEV',
//       priorities: ['Decide what we’re going to do with MEV stuff', 'What to prioritize vs. what not to'],
//     },
//   ];

//   return (
//     <div className="container2">
//       <div className="card2">
//         <h1>Top Priorities(From Last Meeting)</h1>
//         <ul></ul>
//         {categories.map((category, index) => (
//           <div key={index} className="card3">
//             <h2>{category.title}</h2>
//             <ul>
//               {category.priorities.map((priority, index) => (
//                 <li key={index}>{priority}</li>
//               ))}
//             </ul>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// }

// export default Priorities;
function Priorities() {
    const categories = [
      {
        title: 'WM',
        priorities: [
          'Set All Timeline Stuff',
  
          'Maintain overall marketing for both platforms',
        ],
      },
      {
        title: 'COMPANY',
        priorities: [
          'Slide Documents',
  
          'Have WaveApps all setup, and invoice ready',
        ],
      },
      {
        title: 'BLXN+TRICKLE',
        priorities: [
          'Think of final details for BLXN new idea',
  
          'Set Trickle timeline',
        ],
      },
      {
        title: 'MEV',
        priorities: ['Decide what we’re going to do with MEV stuff', 'What to prioritize vs. what not to'],
      },
    ];
  
    return (
      <div className="container">
        <div className="card">
          <h1>Top Priorities(From Last Meeting)</h1>
          <ul></ul>
          {categories.map((category, index) => (
            <div key={index} className="card">
              <h2>{category.title}</h2>
              <ul>
                {category.priorities.map((priority, index) => (
                  <li key={index}>{priority}</li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      </div>
    );
  }
  
  export default Priorities;
  