import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import firebase from 'firebase/compat/app';

const firebaseConfig = {
    apiKey: "AIzaSyAqACR5qUut9wGKNLmjOHy4Nrq6nWh021U",
    authDomain: "rjsboard.firebaseapp.com",
    databaseURL: "https://rjsboard-default-rtdb.firebaseio.com",
    projectId: "rjsboard",
    storageBucket: "rjsboard.appspot.com",
    messagingSenderId: "368916075962",
    appId: "1:368916075962:web:034ef8d3c679f8002b7983",
    measurementId: "G-0842SFC9NB"
  };

firebase.initializeApp(firebaseConfig);
// const app = initializeApp(firebaseConfig);
const auth = firebase.auth();
const db = getFirestore();

export { auth, db };


