import React, { useState, useEffect, useRef } from 'react';

function Navbar() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [showInternalDropdown, setShowInternalDropdown] = useState(false);
  const [showExternalDropdown, setShowExternalDropdown] = useState(false);
  const [showReportsDropdown, setShowReportsDropdown] = useState(false);
  const [showCredentialsDropdown, setShowCredentialsDropdown] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const linksContainerRef = useRef(null);
  const searchResultsRef = useRef(null);


  // Add event listener to window to detect scrolling
  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Toggle background color based on scroll position
  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
      setSearchQuery('');
    } else {
      setIsScrolled(false);
    }
  };


  // Show/hide dropdown on hover
  const handleInternalHover = (show) => {
    setShowInternalDropdown(show);
  };

  const handleExternalHover = (show) => {
    setShowExternalDropdown(show);
  };

  const handleReportsHover = (show) => {
    setShowReportsDropdown(show);
  };

  const handleCredentialsHover = (show) => {
    setShowCredentialsDropdown(show);
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    const results = [];
  
    // find all 'a' tags in the page
    const links = document.querySelectorAll('a');
  
    const uniqueLinks = new Set();
    links.forEach((link) => {
      // check if the link's text includes the search query
      if (link.innerText.toLowerCase().includes(searchQuery.toLowerCase())) {
        const linkInfo = { text: link.innerText, href: link.href };
        const linkString = JSON.stringify(linkInfo);
        // add the link's text and href to the results array
        if (!uniqueLinks.has(linkString)) {
          uniqueLinks.add(linkString);
          results.push(linkInfo);
        }
      }
    });
  
    setSearchResults(results);
  
    if (searchQuery === '') {
      setSearchResults([]);
    }
  };
  
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (searchResults.length > 0 && !e.path.includes(searchResultsRef.current)) {
        setSearchResults([]);
      }
    };
  
    document.addEventListener('click', handleOutsideClick);
    return () => document.removeEventListener('click', handleOutsideClick);
  }, [searchResults]);
  
  
  
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (searchResultsRef.current && !searchResultsRef.current.contains(e.target)) {
        setSearchResults([]);
      }
    };
  
    document.addEventListener('click', handleOutsideClick);
    return () => document.removeEventListener('click', handleOutsideClick);
  }, []);
        


  return (
    <nav
      className={`navbar ${isScrolled ? 'navbar-scrolled' : ''}`}
      style={{ backgroundColor: isScrolled ? '#444' : 'transparent' }}
    >
      <ul>
        <li><a href="#">Home</a></li>
        <li
          onMouseEnter={() => handleInternalHover(true)}
          onMouseLeave={() => handleInternalHover(false)}
        >
          <a href="#">Internal</a>
          {showInternalDropdown && (
            <div className="dropdown-menu">
              <a href="https://docs.google.com/spreadsheets/d/1hFR_vTLZj77bsT-Z18r_uartuMbLq1XEODEoCGnKAcc/edit?usp=sharing">Project Records</a>
              <a href="https://docs.google.com/spreadsheets/d/1NkLrUL72KEfH70neYltxG64UPShM24eO8j6xBFIqQ-M/edit#gid=1262839641">P/L R/S/L</a>
              <a href="#">P/L Tokens</a>
              <a href="https://docs.google.com/spreadsheets/u/0/d/1geN1NCE_Z-Lq4bKKzwrJQ2GFZE2b8DUnFehNhpZFABA/edit">Full Investment Strategy</a>
              <a href="#">Project Ideas</a>
              <a href="https://docs.google.com/spreadsheets/d/1NkLrUL72KEfH70neYltxG64UPShM24eO8j6xBFIqQ-M/edit#gid=206395203">Internal Reports</a>
              <a href="#">Internal Wallets</a>
              <a href="#">Internal Research</a>
              <a href="https://drive.google.com/drive/u/0/folders/1uNukQzu4EiD9wKsCKwKFf3hDfUmMd6ia">Internal Documents</a>

            </div>
          )}
        </li>
        <li
          onMouseEnter={() => handleExternalHover(true)}
          onMouseLeave={() => handleExternalHover(false)}
        >
          <a href="#">External</a>
          {showExternalDropdown && (
            <div className="dropdown-menu">
              <a href="https://next.waveapps.com/4b57ec82-e1c9-465c-b39e-28b2958e0dde/dashboard/">Bookkeeping</a>
              <a href="https://docs.google.com/spreadsheets/d/1NkLrUL72KEfH70neYltxG64UPShM24eO8j6xBFIqQ-M/edit#gid=1539093411">General Expenses/Salaries</a>
              <a href="#">External Transaction Sheet</a>
              <a href="#">Tax Documents</a>
              <a href="">External Reports</a>
              <a href="#">External Wallets</a>
              <a href="">External Logins</a>
              <a href="https://drive.google.com/drive/folders/14tJm4Ev7_Rux-DgZocZ60iw-X7B9qCul?usp=share_link">Corporate Documents</a>
            </div>
          )}
        </li>
        <li      onMouseEnter={() => handleReportsHover(true)}
      onMouseLeave={() => handleReportsHover(false)}
    >
      <a href="#">Reports</a>
      {showReportsDropdown && (
        <div className="dropdown-menu">
          <a href="https://docs.google.com/spreadsheets/d/1NkLrUL72KEfH70neYltxG64UPShM24eO8j6xBFIqQ-M/edit#gid=1262839641">P/L R/S/L Reports</a>
          <a href="https://www.accointing.com/app/portfolio/overview">P/L Token Reports</a>
          <a href="https://docs.google.com/spreadsheets/d/1ZccHQQfW_wKnJIxko9GFsM7ruwNPqjm3VXFLD7C9baY/edit#gid=1589314090">KuCoin Bots Reports</a>
          <a href="https://docs.google.com/spreadsheets/d/1hFR_vTLZj77bsT-Z18r_uartuMbLq1XEODEoCGnKAcc/edit?usp=sharing">Dapp Reports</a>
        </div>
      )}
    </li>
    <li
      onMouseEnter={() => handleCredentialsHover(true)}
      onMouseLeave={() => handleCredentialsHover(false)}
    >
      <a href="#">Credentials</a>
      {showCredentialsDropdown && (
        <div className="dropdown-menu">
          <a href="#">Wallets</a>
          <a href="#">Logins</a>
        </div>
      )}
    </li>
    <li className="navbar-search">
  <form onSubmit={handleSearchSubmit}>
    <input
      type="text"
      placeholder="IshDick NFTs..."
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
    />
    <button type="submit">
      Search
    </button>
  </form>
  {searchResults.length > 0 && (
    <div className="search-results" ref={searchResultsRef}>
      {searchResults.map((result) => (
        <a href={result.href} key={result.href}>
          {result.text}
        </a>
      ))}
    </div>
  )}
</li>


  </ul>
</nav>
);
}

export default Navbar;
