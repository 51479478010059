import React from 'react';

const GoogleCalendar = () => {
  return (
    <div className="google-calendar">
      <h1 className="upcoming-deadlines">Upcoming Deadlines</h1>
      <iframe title="Google Calendar" src="https://calendar.google.com/calendar/embed?height=600&wkst=1&bgcolor=%23ffffff&ctz=America%2FNew_York&showNav=1&showTitle=0&src=Y18zNTI4ZGZhNGEyNWQ2OGY2NzllYzAzZDdkNmQ1YjgyYTNjMzQ4ZDMzZTUxN2IxYWMwMmI3ODcxZDBjMGMxZDA1QGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&src=Y19iZGQxYTYyMGY3NjI3MmM3Y2UyZDVlNGQ2ZDYxYzU0NzgxN2E0OTUzNGE3YTllY2FjNDc4M2IxYzkwNjBhMjQxQGdyb3VwLmNhbGVuZGFyLmdvb2dsZS5jb20&color=%23E4C441&color=%23B39DDB" style={{ border: 'solid 1px #777' }} width="600" height="400" frameBorder="0" marginHeight="0" marginWidth="0" scrolling="no"></iframe>
    </div>
  );
};

export default GoogleCalendar;
