import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { useState } from 'react';
import { auth } from "../firebase";

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState(null);
    const [showModal, setShowModal] = useState(false);
  
    const handleEmailChange = (event) => {
      setEmail(event.target.value);
    };
  
    const handlePasswordChange = (event) => {
      setPassword(event.target.value);
    };
  
    const handleSubmit = async (event) => {
      event.preventDefault();
      try {
        await auth.signInWithEmailAndPassword(email, password);
        // User is signed in
        setShowModal(false);
      } catch (error) {
        setErrorMessage(error.message);
      }
    };
  
    const toggleModal = () => {
      setShowModal(!showModal);
    }
  
    return (
      <div className="login-container2">
        <h1 className="title">Please log in to view Lab3 Dashboard</h1>
        <button className="login-button" onClick={toggleModal}>Login</button>
        {showModal && (
          <div className="modal-container">
            <div className="modal-content">
              <h2 className="modal-title">Login</h2>
              {errorMessage && <p className="error-message">{errorMessage}</p>}
              <form onSubmit={handleSubmit}>
                <label htmlFor="email-input">Email:</label>
                <input id="email-input" type="email" className="input-field" value={email} onChange={handleEmailChange} />
                <label htmlFor="password-input">Password:</label>
                <input id="password-input" type="password" className="input-field" value={password} onChange={handlePasswordChange} />
                <button type="submit" className="modal-button">Login</button>
              </form>
              <button className="close-button" onClick={toggleModal}>Close</button>
            </div>
          </div>
        )}
      </div>
    );
  }
  
  export default Login;
  
