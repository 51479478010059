import React from 'react';

const Docs = () => {
  return (
    <div className="docs-container">
      <div className="docs-card">
        <h2 className="docs-card-title">How-To</h2>
        <div className="docs-card-body">
          <li><a href="#">Create & Deploy A Smart Contract</a></li>
          <li><a href="#">Create a Basic React Project</a></li>
          <li><a href="https://docs.google.com/spreadsheets/d/1ZccHQQfW_wKnJIxko9GFsM7ruwNPqjm3VXFLD7C9baY/edit#gid=1937429215">Manipulate BSCScan Data</a></li>
          <li><a href="#">Slide Cancel</a></li>
          <li><a href="#">Invest in ROI dApps</a></li>
          <li><a href="#">Invest in Token</a></li>
          <li><a href="#">Market ROI dApps</a></li>
          <li><a href="#">Create MEV Bots</a></li>
          <li><a href="#">Market ROI dApps</a></li>
        </div>
      </div>
      <div className="docs-card">
        <h2 className="docs-card-title">Resources</h2>
        <div className="docs-card-body">
          <li><a href="https://docs.google.com/spreadsheets/d/1NkLrUL72KEfH70neYltxG64UPShM24eO8j6xBFIqQ-M/edit#gid=0">Role Breakdown</a></li>
          <li><a href="#">Role Duties</a></li>
          <li><a href="https://drive.google.com/drive/folders/13KHDIoMmgz5oKZWCelyPYtVfmMjNodI5?usp=share_link">Meeting Notes</a></li>
          <li><a href="#">Portfolio Debank</a></li>
          <li><a href="https://www.kucoin.com/assets/bot-account">KuCoin Bots</a></li>
          <li><a href="#">Dune Analytics</a></li>
          <li><a href="#">Evaluate Potential Investment</a></li>
          <li><a href="#">Backtest Token</a></li>
          <li><a href="#">TradingView Analytics</a></li>
        </div>
      </div>
    </div>
  );
};

export default Docs;
