import React, { useState } from 'react';

function Carousel() {
  const [currentSlide, setCurrentSlide] = useState(1);

  const handleNextSlide = () => {
    setCurrentSlide(currentSlide === 2 ? 1 : 2);
  };

  const handlePrevSlide = () => {
    setCurrentSlide(currentSlide === 1 ? 2 : 1);
  };

  const [isTestingExpanded, setIsTestingExpanded] = useState(false);
  const [isExecutionExpanded, setIsExecutionExpanded] = useState(false);

  const handleToggleTesting = () => {
    setIsTestingExpanded(!isTestingExpanded);
  };

  const handleToggleExecution = () => {
    setIsExecutionExpanded(!isExecutionExpanded);
  };

  return (
    <div className="carousel-container">
      <div className="investment-header">
        <h1>Investment Strategies</h1>
      </div>

      <button className="carousel-prev" onClick={handlePrevSlide} />
      <button className="carousel-next" onClick={handleNextSlide} />

      <div className={`slide ${currentSlide === 1 ? 'active' : ''}`}>
        <h2 onClick={handleToggleTesting}>Testing</h2>
        <table style={{ display: isTestingExpanded ? 'table' : 'none' }}>
          <thead>
            <tr>
              <th>Investment</th>
              <th>Method</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><a href="#">Investment 1</a></td>
              <td>Method 1</td>
              <td>Placeholder details</td>
            </tr>
            <tr>
              <td><a href="#">Investment 2</a></td>
              <td>Method 2</td>
              <td>Placeholder details</td>
            </tr>
            {/* Repeat the above two rows to add more data */}
          </tbody>
        </table>

        <h2 onClick={handleToggleExecution}>Execution</h2>
        <table style={{ display: isExecutionExpanded ? 'table' : 'none' }}>
          <thead>
            <tr>
              <th>Investment</th>
              <th>Method</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><a href="#">Investment 1</a></td>
              <td>Method 1</td>
              <td>Placeholder details</td>
            </tr>
            <tr>
              <td><a href="#">Investment 2</a></td>
              <td>Method 2</td>
              <td>Placeholder details</td>
            </tr>
            {/* Repeat the above two rows to add more data */}
          </tbody>
        </table>
      </div>

      <div className={`slide ${currentSlide === 2 ? 'active' : ''}`}>
        <h2 onClick={handleToggleTesting}>Testing</h2>
        <table style={{ display: isTestingExpanded ? 'table' : 'none' }}>
          <thead>
            <tr>
              <th>Investment</th>
              <th>Method</th>
              <th>Details</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><a href="#">Investment 11</a></td>
              <td>Method 11</td>
          <td>Placeholder details</td>
              </tr>
        <tr>
          <td><a href="#">Investment 12</a></td>
          <td>Method 12</td>
          <td>Placeholder details</td>
        </tr>
        <tr>
          <td><a href="#">Investment 12</a></td>
          <td>Method 12</td>
          <td>Placeholder details</td>
        </tr>
        <tr>
          <td><a href="#">Investment 12</a></td>
          <td>Method 12</td>
          <td>Placeholder details</td>
        </tr>
        {/* Repeat the above two rows to add more data */}
      </tbody>
    </table>

    <h2 onClick={handleToggleExecution}>Execution</h2>
    <table style={{ display: isExecutionExpanded ? 'table' : 'none' }}>
      <thead>
        <tr>
          <th>Investment</th>
          <th>Method</th>
          <th>Details</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><a href="#">Investment 11</a></td>
          <td>Method 11</td>
          <td>Placeholder details</td>
        </tr>
        <tr>
          <td><a href="#">Investment 12</a></td>
          <td>Method 12</td>
          <td>Placeholder details</td>
        </tr>
        {/* Repeat the above two rows to add more data */}
      </tbody>
    </table>
  </div>
</div>

);
}

export default Carousel;





  
