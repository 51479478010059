import React from 'react';

function Graphs() {
  return (
    <div className="graphs">
      <div className="graph-container">
        {/* Graph 1 */}
        <div className="graph">
          <h3>All Time Income/Expenses</h3>
          <iframe src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQK827N_wagzAvTpuY9MmqdNdhtoiS7OhEgOqAIh1PEZNZw1jxPJhFNzM6txpVwRbiO7D0ijQ-J2HNf/pubchart?oid=1718851649&amp;format=interactive"></iframe>
        </div>

        {/* Graph 2 */}
        <div className="graph">
          <h3>All Time Investment Diversity</h3>
          <iframe src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQK827N_wagzAvTpuY9MmqdNdhtoiS7OhEgOqAIh1PEZNZw1jxPJhFNzM6txpVwRbiO7D0ijQ-J2HNf/pubchart?oid=1018527427&amp;format=interactive"></iframe>
        </div>
      </div>
    </div>
  );
}

export default Graphs;
