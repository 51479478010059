import React from 'react';

const ProjectGraph = () => {
  const chartLinks = [
    {
      title: "WML",
      src: "https://docs.google.com/spreadsheets/d/e/2PACX-1vSP7cmuJW5E3GmG2Uylcj-1kU1i0_GJYnRGIHU6o0PLO6pi0Ljd4_4ytIi-i_oG-uPgd6GxVkPBfrpQ/pubchart?oid=1197063076&amp;format=interactive",
    },
    {
      title: "BLXN Limitless",
      src: "https://docs.google.com/spreadsheets/d/e/2PACX-1vSP7cmuJW5E3GmG2Uylcj-1kU1i0_GJYnRGIHU6o0PLO6pi0Ljd4_4ytIi-i_oG-uPgd6GxVkPBfrpQ/pubchart?oid=1926469740&amp;format=interactive",
    },
    {
      title: "BLXN BUSD Hike",
      src: "https://docs.google.com/spreadsheets/d/e/2PACX-1vSP7cmuJW5E3GmG2Uylcj-1kU1i0_GJYnRGIHU6o0PLO6pi0Ljd4_4ytIi-i_oG-uPgd6GxVkPBfrpQ/pubchart?oid=290401725&amp;format=interactive",
    },
    {
      title: "BLXN BUSD Rocket",
      src: "https://docs.google.com/spreadsheets/d/e/2PACX-1vSP7cmuJW5E3GmG2Uylcj-1kU1i0_GJYnRGIHU6o0PLO6pi0Ljd4_4ytIi-i_oG-uPgd6GxVkPBfrpQ/pubchart?oid=1659071807&amp;format=interactive",
    },
    {
      title: "WM V1",
      src: "https://docs.google.com/spreadsheets/d/e/2PACX-1vSP7cmuJW5E3GmG2Uylcj-1kU1i0_GJYnRGIHU6o0PLO6pi0Ljd4_4ytIi-i_oG-uPgd6GxVkPBfrpQ/pubchart?oid=1398647358&amp;format=interactive",
    },
    {
      title: "Build Vacation",
      src: "https://docs.google.com/spreadsheets/d/e/2PACX-1vSP7cmuJW5E3GmG2Uylcj-1kU1i0_GJYnRGIHU6o0PLO6pi0Ljd4_4ytIi-i_oG-uPgd6GxVkPBfrpQ/pubchart?oid=1208385864&amp;format=interactive",
    },
    {
      title: "Lev V2",
      src: "https://docs.google.com/spreadsheets/d/e/2PACX-1vSP7cmuJW5E3GmG2Uylcj-1kU1i0_GJYnRGIHU6o0PLO6pi0Ljd4_4ytIi-i_oG-uPgd6GxVkPBfrpQ/pubchart?oid=1519206904&amp;format=interactive",
    },
    {
      title: "BuildBNB",
      src: "https://docs.google.com/spreadsheets/d/e/2PACX-1vSP7cmuJW5E3GmG2Uylcj-1kU1i0_GJYnRGIHU6o0PLO6pi0Ljd4_4ytIi-i_oG-uPgd6GxVkPBfrpQ/pubchart?oid=1926469740&amp;format=interactive",
    },
    {
      title: "LevV1",
      src: "https://docs.google.com/spreadsheets/d/e/2PACX-1vSP7cmuJW5E3GmG2Uylcj-1kU1i0_GJYnRGIHU6o0PLO6pi0Ljd4_4ytIi-i_oG-uPgd6GxVkPBfrpQ/pubchart?oid=1519206904&amp;format=interactive",
    }
  ];

  const renderChart = (chartLink) => {
    return (
      <div className="project-chart-container" key={chartLink.title}>
        <h2>{chartLink.title}</h2>
        <iframe src={chartLink.src} width="500" height="400"></iframe>
      </div>
    );
  };

  return (
    <div className="project-graph">
      <h1>Project Records</h1>
      <div className="chart-row">
        {chartLinks.slice(0, 3).map(renderChart)}
      </div>
      <div className="chart-row">
        {chartLinks.slice(3, 6).map(renderChart)}
      </div>
      <div className="chart-row">
        {chartLinks.slice(6, 9).map(renderChart)}
      </div>
    </div>
  );
};

export default ProjectGraph;
